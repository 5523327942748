/**
 * バージョン案内モーダルの状態を更新する
 */
export const updateVersionModal = (
  setShowVersionModal: (show: boolean) => void,
  hasShownModalOnPage: boolean,
  setHasShownModalOnPage: (shown: boolean) => void
) => {
  if (!hasShownModalOnPage) {
    setShowVersionModal(true);
    setHasShownModalOnPage(true);
  }
};
