import * as Vax from '@/types/vax'
import DirectusClient from "@/services/DirectusClient";
import {redirectOnErrorStatus} from "@/utils/custom/locationHelper";

type Cms = {
  directus_users: Vax.Directus_Users
}

/**
 * 画面表示時にユーザ情報を取得する
 */
export const getUserDataIfTokenIsValid = async (): Promise<Cms['directus_users'] | null> => {
  try {
    const directus = DirectusClient.getInstance();
    await directus.auth.refreshIfExpired()
    // ログイン後、ユーザIDを取得
    const user = await directus.users.me.read({ fields: ['id'] })

    return user as Cms['directus_users']
  } catch (e) {
    console.dir(e)
    return null
  }
}

export const cmsLogin = async (id: string, password: string): Promise<Cms['directus_users'] | null> => {
  const directus = DirectusClient.getInstance();
  await directus.auth.login({
    email: id || '',
    password: password || '',
  })

  // ログイン後、ユーザIDを取得
  const user = await directus.users.me.read({ fields: ['id'] })

  return user as Cms['directus_users']
}

export const cmsLogout = async (): Promise<void> => {
  try {
    const directus = DirectusClient.getInstance();
    await directus.auth.logout()
    // 上記ログアウト関数だととauth_expires_atが残り、次にauth.refresh()を叩くとエラーになる。
    // 具体的には、ヘルプセンターでDirectusインスタンス作成時にauth.refresh()が実行されエラーとなり画面が表示できなくなる
    // そのため、refreshIfExpiredを実行しauth_expires_atを削除する
    await directus.auth.refreshIfExpired()
  } catch (e) {
    console.dir(e)
  }
}

// 以下パスワードの正規表現を変更する際は、DirectusのSecurity[Auth Password Policy]も変更する必要があるので注意
// https://docs.directus.io/user-guide/settings/project-settings.html#security
export const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{12,}$/;

/**
 * エラーメッセージを日本語に変換する
 * @param englishMessage 英語のエラーメッセージ
 */
export function translateErrorMessage(englishMessage: string): string {
  // エラー文言を日本語に変換
  const errorMessages: Record<string, string> = {
    "\"email\" must be a valid email": "メールアドレスの形式が正しくありません。",
    "Invalid user credentials.": "メールアドレスまたはパスワードが正しくありません。",
    // 他のエラーメッセージを追加する際はここに記述
  };

  // デフォルトメッセージ
  const defaultErrorMessage = "エラーが発生しました。もう一度お試しください。";

  return errorMessages[englishMessage] || defaultErrorMessage;
}

/**
 * レスポンスヘッダをチェックし、エラー時はリダダイレクトする
 * @param response graphqlのレスポンス(query or mutaion)
 */
export const checkResponseAndRedirect = (response: any) => {
  //
  //  1. レスポンスデータをJSONシリアライズ
  //
  const stringData = JSON.stringify(response)
  const parseData = JSON.parse(stringData)

  //
  // 2. レスポンスコードをチェック
  //
  const statusCode = parseData?.response?.raw?.code ?? 200
  const xErrorReason = parseData?.response?.headers?.['x-error-reason'] ?? '';

  //
  // 3. レスポンスコードに応じた処理
  //
  redirectOnErrorStatus(statusCode, xErrorReason)

}

