// react
import React, {useEffect, useState} from 'react'
import {useRouter} from 'next/router'

// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import {CacheProvider, EmotionCache} from '@emotion/react';
// next
import {NextPage} from 'next';
import Head from 'next/head';
import {AppProps} from 'next/app';
// atoms components
import Progress from "@/components/custom/atoms/Progress";
// utils
import {QueryClient, QueryClientProvider, QueryKey} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import UseMessageListener from '@/hooks/custom/useMessageListener';
import {GlobalSettingsProvider} from '@/components/custom/contexts/GlobalSettingsProvider';
import ChatSettings from "@/components/settings/ChatSettings";
import {getVaxToken} from "@/utils/custom/getVaxToken";

// Directus SDK
import DirectusClient from "@/services/DirectusClient";
// auth
import {AuthProvider} from '@/components/custom/contexts/AuthContextProvider';
// version modal
import VersionMismatchModal from "@/components/version/VersionMismatchModal";
import {updateVersionModal} from "@/utils/custom/updateVersionModal";
import {getProjectKey, canDisplayContent, getEnvironment} from "@/utils/custom/locationHelper";
import {checkResponseAndRedirect} from "@/utils/custom/authHelper";
// bugsnag component
import { ErrorLogsHelper } from '@/utils/custom/ErrorLogsHelper';
import ErrorBoundary from '../components/custom/organisms/ErrorBoundary';
import createEmotionCache from '../utils/createEmotionCache';
// theme
import ThemeProvider from '../theme';
// locales
// import ThemeLocalization from '../locales';
// components
import ProgressBar from '../components/progress-bar';
import SnackbarProvider from '../components/snackbar';
import { MotionLazyContainer } from '../components/animate';
import { ThemeSettings } from '../components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

// ----------------------------------------------------------------------

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

// ErrorLogのヘルパークラスをインスタンス化
const LogsHelper = ErrorLogsHelper.createErrorLogsHelper()

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  // バージョン案内モーダルの表示状態を管理するフラグ
  const [showVersionModal, setShowVersionModal] = useState(false);
  // 現在のページでバージョン案内モーダルを表示済みかどうかを管理するフラグ
  const [hasShownModalOnPage, setHasShownModalOnPage] = useState(false);

  const router = useRouter();

  // GraphQLのクエリクライアントを初期化
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data: any) => {
          //
          //  レスポンスデータをチェックし、エラー時はリダイレクトする
          //
          checkResponseAndRedirect(data)

          LogsHelper.onSuccess(data)
        },
        onError: (error: any) => LogsHelper.onError(error)
      },
      mutations: {
        onSuccess: (data: any) => LogsHelper.onSuccess(data),
        onError: (error: any) => {
          //
          //  レスポンスデータをチェックし、エラー時はリダイレクトする
          //
          checkResponseAndRedirect(error)

          LogsHelper.onError(error)
        }
      }
    },
  });

  // pjKeyを取得するために必要な関数・変数
  const {isReady, pathname} = useRouter()
  const [displayContent, setDisplayContent] = useState<boolean>(false)
  const [pjKey, setPjKey] = useState<string>('')

  // pjKeyが取得できる状態を監視
  useEffect(() => {
    // isReadyがtrueになった時の1度だけ実行される
    if (isReady && pjKey === '' && !displayContent) {
      const projectKey = getProjectKey();
      const environment = getEnvironment();
      // 初期値のセット
      setDisplayContent(canDisplayContent())
      setPjKey(projectKey)

      // DirectusClientの初期化
      const opts = {
        transport: {
          headers: {
            'X-VAX-PJKEY': projectKey,
            'X-VAX-SITE': environment
          }
        }
      }
      DirectusClient.initialize(opts)}
  }, [isReady, pjKey, displayContent])

  // グローバル関数をセットする
  useEffect(() => {
    window.__getVaxToken__ = getVaxToken;
  }, [])

  // グローバルにバージョン案内モーダルの状態を更新する関数をセット
  // 状態が更新されるごとに関数は更新される
  useEffect(() => {
    window.__vaxUpdateVersionModal__ = (() => {
      updateVersionModal(setShowVersionModal, hasShownModalOnPage, setHasShownModalOnPage);
    });
  }, [hasShownModalOnPage]);

  // ルート（ページ）が変更されるごとにバージョン案内モーダルの状態をリセット
  useEffect(() => {
    const handleRouteChange = () => {
      setShowVersionModal(false);
      setHasShownModalOnPage(false);
    };

    // ルート変更完了時のイベントを監視し、モーダル表示を更新
    router.events.on('routeChangeComplete', handleRouteChange);

    // このエフェクトが不要になった際にイベントリスナーを解除
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    }
  }, [router.events]);

  // メッセージを受け取るためのカスタムフック
  UseMessageListener();

  // ページリロード関数
  const handleReload = () => {
    window.location.reload();
  };

  // pjKeyが取得できるまでローディングを表示
  if (pjKey === '') {
    return (
      <Progress
        size="100%"
        sx={{
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
        }}
      />
    );
  }

  // ErrorLogsHelperの初期化が複数回起こらないようにする
  if (!LogsHelper.getIsStarted()) LogsHelper.init(pjKey)

  // エラーページかログインページを表示するための分岐
  // サイドバーなどの不要な要素は読み込まない。理由はgraphQLのリクエストがとんで無限ループするため
  if (displayContent) {
    return (
      <AuthProvider>
        <ThemeProvider>
          <SnackbarProvider>
          <Component {...pageProps} />
          </SnackbarProvider>
        </ThemeProvider>
      </AuthProvider>
    )
  }

  // pjKeyを取得後、メインの処理を実行
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width"/>
      </Head>

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalSettingsProvider>
            <AuthProvider>
              <ThemeSettings pjKey={pjKey}>
                <ChatSettings pjKey={pjKey}>
                  <SnackbarProvider>
                    <ProgressBar/>
                    {
                      getLayout(
                        <ErrorBoundary pjKey={pjKey} logsHelper={LogsHelper}>
                          <Component {...pageProps} />
                          <VersionMismatchModal
                            isOpen={showVersionModal}
                            onClose={() => setShowVersionModal(false)}
                            onReload={handleReload}
                          />
                        </ErrorBoundary>
                      )
                    }
                  </SnackbarProvider>
                </ChatSettings>
              </ThemeSettings>
            </AuthProvider>
          </GlobalSettingsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
}
