import { createContext, useMemo, useReducer } from 'react';

// ----------------------------------------------------------------------

type GlobalSettingsProps = {
  is_enabled: boolean;
  is_public: boolean;
  title: string;
  icon: string;
  logo: string;
  search_type: 'incremental' | 'submit' | '';
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

// ----------------------------------------------------------------------

const GlobalSettingsContext = createContext<any>([]);

function globalSettingsReducer(
  state: GlobalSettingsProps,
  action: { type: string; payload: GlobalSettingsProps }
) {
  switch (action.type) {
    case 'loading': {
      return { ...state };
    }
    case 'success': {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const GlobalSettingsProvider = ({ children }: Props) => {
  const initialState: GlobalSettingsProps = {
    is_enabled: true,
    is_public: false,
    title: '',
    icon: '',
    logo: '',
    search_type: '',
  };
  const [state, dispatch] = useReducer(globalSettingsReducer, initialState);
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <GlobalSettingsContext.Provider value={value}>{children}</GlobalSettingsContext.Provider>;
};

export { GlobalSettingsProvider, GlobalSettingsContext };
