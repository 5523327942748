import DirectusClient from "@/services/DirectusClient";

// ----------------------------------------------------------------------

/**
 * VAXのトークンを取得する
 * なければ空文字を返す
 */
export const getVaxToken = async () => {

  // トークンが期限切れの場合はリフレッシュする
  await DirectusClient.refreshIfExpiredWrapper();

  // トークンを取得
  const directus = DirectusClient.getInstance();
  let token = await directus.auth.token;
  token = token ?? '';

  // tokenがnullなら空文字を返し、それ以外ならtokenを返す
  return token || '';
};
