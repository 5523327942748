import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface VersionMismatchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
}

const VersionMismatchModal: React.FC<VersionMismatchModalProps> = ({ isOpen, onClose, onReload }) => (
  <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
    <DialogTitle sx={{ pb: 2 }}>ページを再読み込みしてください</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={(theme) => ({
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
      })}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent sx={{ typography: 'body2' }}>
      更新されたページをご覧いただけます。
    </DialogContent>
    <DialogActions>
      <Button onClick={onReload} variant="contained" color="primary">
        再読み込み
      </Button>
    </DialogActions>
  </Dialog>
);

export default VersionMismatchModal;
