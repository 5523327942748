import { Directus } from '@directus/sdk';
import {redirectOnAuthStatus} from "@/utils/custom/locationHelper";

const DIRECTUS_URL = process.env.NEXT_PUBLIC_DIRECTUS_URL as string;

/**
 * Directus SDKのインスタンスを生成する
 */
class DirectusClient {
  private static instance: Directus<any> | null = null;

  private static isRunning = false; // 実行中かを示すフラグ

  private static cachedPromise:  Promise<void> | null = null; // 最初の実行結果をキャッシュするPromise

  private static appIsPublic = true; // ヘルプセンターの公開設定

  public static initialize(options: { transport: { headers: Record<string, string>} }) {
    if (!DirectusClient.instance) {
      DirectusClient.instance = new Directus(DIRECTUS_URL, options);
    }
  }

  public static getInstance(): Directus<any> {
    if (!DirectusClient.instance) {
      throw new Error('DirectusClientが初期化されていません');
    }
    return DirectusClient.instance;
  }

  /**
   * 一般公開かどうかを設定する
   * @param isPublic
   */
  public static setPublic(isPublic: boolean) {
    this.appIsPublic = isPublic;
  }

  public static getPublic() {
    return this.appIsPublic;
  }

  /**
   * トークンをリフレッシュする
   * ただし、実行中の場合は既存のPromiseを返すことで複数回呼ばれないようにする
   */
  public static async refreshIfExpiredWrapper() {
    if (this.isRunning) {
      // 実行中の場合、既存のPromiseを返す
      return this.cachedPromise;
    }

    const directus = this.getInstance();
    this.isRunning = true; // 実行中フラグを設定
    this.cachedPromise = new Promise((resolve, reject) => {
      console.log("タスク開始");

      // 非同期処理（例: APIコール）
      directus.auth.refreshIfExpired()
        .then(result => {
          console.log("タスク完了", result);
          resolve(result); // 実行結果を返す
        })
        .catch(error => {
          console.error("タスクエラー", error);

          // 公開設定を取得
          const isPublic = this.getPublic();
          // 限定公開の時に、エラーが発生した場合はログインページへリダイレクト
          // レスポンスコードでチェックできないためリクエストエラーと公開設定で判定している
          if (!isPublic) {
            redirectOnAuthStatus('signout')
          }

          reject(error);
        })
        .finally(() => {
          // 状態をリセット
          this.isRunning = false;
          this.cachedPromise = null;
        });
    });

    return this.cachedPromise;
  }
}

export default DirectusClient;
