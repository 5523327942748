import { useContext, useEffect } from 'react';
import Head from 'next/head';
//
// import ThemeRtlLayout from './ThemeRtlLayout';
// import ThemeColorPresets from './ThemeColorPresets';
// import SettingsDrawer from './drawer';
import { SiteConfigurations, useGetSiteConfigurationsQuery } from '@/hooks/custom/helpcenter.hooks';
import { changePaletteColor } from '@/utils/custom/createColorHelpter';
import { GlobalSettingsContext } from '@/components/custom/contexts/GlobalSettingsProvider';
import DirectusClient from "@/services/DirectusClient";
import process from "process";
import ThemeContrast from './ThemeContrast';

// contexts

// ----------------------------------------------------------------------

type Props = {
  pjKey: string;
  children: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function ThemeSettings({pjKey, children}: Props) {

  //
  const versionsFilter = process.env.VERSION_STATUS === 'preview' ? {
    number: {
      _eq: 0
    }
  } : {
    status: {
      _eq: process.env.VERSION_STATUS
    }
  }
  const filter = {
    _and: [
      {
        domain_id: {
          versions: {
            source_id: {
              key: {
                _eq: pjKey
              }
            }
          }
        }
      },
      {
        domain_id: {
          versions: {
            ...versionsFilter
          }
        }
      }
    ]
  }
  const { data, isLoading, isSuccess, isError } = useGetSiteConfigurationsQuery({ filter });
  const { dispatch } = useContext(GlobalSettingsContext);
  //
  const siteConfig = (data?.SiteConfigurations && data?.SiteConfigurations[0] as SiteConfigurations) ?? {
    color_main: '',
    color_sub: '',
    is_enabled: false,
    is_public: false,
    title: '',
    icon: {
      id: ''
    },
    logo: {
      id: ''
    },
    domain_id: {
      versions: {
        source_id: {
          search_type: ''
        }
      }
    }
  };
  const primaryColor = (siteConfig?.color_main as string) ?? '';
  const secondaryColor = (siteConfig?.color_sub as string) ?? '';
  const icon = '/favicon.ico';
  // VAMの設定で上書きする色値を生成
  const colorSetting = changePaletteColor(primaryColor, secondaryColor);

  // 初期設定をGlobalSettingsContextに保存
  useEffect(() => {
    const versions = siteConfig?.domain_id?.versions;

    // versionsが配列の場合は、最初の要素のsource_idからsearch_typeを取得
    // そうでない場合はデフォルト値として空文字を使用
    const searchType = Array.isArray(versions) && versions.length > 0
      ? versions[0]?.source_id?.search_type ?? ''
      : '';

    dispatch({
      type: 'success',
      payload: {
        is_enabled: siteConfig?.is_enabled ?? false,
        is_public: siteConfig?.is_public ?? false,
        title: siteConfig?.title ?? '',
        icon: siteConfig?.icon?.id ?? '',
        logo: siteConfig?.logo?.id ?? '',
        search_type: searchType,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (!data?.SiteConfigurations) return null

  // VAMの設定を読み込み中
  if (isLoading || isError) return null

  // VAMの設定がない場合
  const configData = data?.SiteConfigurations ?? [];
  if (configData.length === 0) return null

  // ヘルプセンターのWeb構造化データを反映
  const HELP_CENTER_URL = process.env.NEXT_PUBLIC_HELP_CENTER_URL;
  const configSiteData = data?.CommonSiteConfigurations ?? {};
  const siteName = configSiteData.site_name ?? "";
  const alternateName = configSiteData.alternate_name as string[] ?? [];
  const jsonLd: {
    "@context": string;
    "@type": string;
    "name": string;
    "alternateName"?: string[];
    "url": string;
  } = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": siteName,
    ...((alternateName && alternateName.length > 0) && { alternateName }),
    "url": `${HELP_CENTER_URL}/${pjKey}/`
  }

  // DirectusSDK制御 classに公開設定を渡す
  const isPublic = siteConfig?.is_public ?? false;
  DirectusClient.setPublic(isPublic);

  return (
    <>
      <Head>
        <link rel="icon" href={`${icon}`}/>
        {
          siteName && (
            <>
              <meta property="og:site_name" content={siteName}/>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLd)}}
              />
            </>
          )
        }
      </Head>
      <ThemeContrast colorSetting={colorSetting}>
        {/* <ThemeColorPresets> */}
        {/* <ThemeRtlLayout> */}
        {children}
        {/* <SettingsDrawer /> */}
        {/* </ThemeRtlLayout> */}
        {/* </ThemeColorPresets> */}
      </ThemeContrast>
    </>
  );
}
