/**
 * urlからプロジェクトキーを取得する
 * @returns プロジェクトキー
 */
export const getProjectKey = (): string => {
  const paths = window.location.pathname.split('/');
  const path = (paths[1] === 'test' || paths[1] === 'preview') ? paths[2] : paths[1];
  return path.toString();
}


/**
 * urlから環境名を取得
 * @returns 環境名 (test or preview) + path
 */
export const getEnvironmentPath = (): string => {
  const paths = window.location.pathname.split('/');
  const path = (paths[1] === 'test' || paths[1] === 'preview') ? `/${paths[1]}` : '';
  return path;
}


/**
 * urlから環境名を取得
 * @returns 環境名 (test or preview or prod)
 */
export const getEnvironment = (): string => {
  const paths = window.location.pathname.split('/');
  const enviroment = (paths[1] === 'test' || paths[1] === 'preview') ? `${paths[1]}` : 'prod';
  return enviroment;
}



/**
 * urlから現在のページが403ページかどうかを判定する
 * @returns 403ページかどうか (true: 403ページ, false: 403ページ以外)
 */
export const is403Page = (): boolean => {
  const paths = window.location.pathname.split('/');

  // パスが2つ以下の場合は403ページではない
  if (paths.length < 2) return false;

  // 環境によってパスの位置が変わるので調整
  // 下記は/errors/403/ が存在するかどうかを判定している
  // /test/ または /preview/ が存在する場合はパスの位置が変わる
  // /preview/{project_key}/errors/403/ の場合は paths[3] が errors で paths[4] が 403 になる
  const isProd = getEnvironmentPath() === '';
  const index = isProd ? 2 : 3;
  return paths[index] === 'errors' && paths[index + 1] === '403';
}

/**
 * urlから現在のページがコンテンツを表記しているページか判定する
 * @returns ヘルプセンターのコンテンツページかどうか (true: コンテンツ外, false: コンテンツ内)
 */
export const canDisplayContent = (): boolean => {
  const paths = window.location.pathname.split('/');

  // 環境によってパスの位置が変わるので調整
  // /test/ または /preview/ が存在する場合はパスの位置が変わる
  // 例えば、/preview/{project_key}/errors/403/ の場合は paths[3] が errors で paths[4] が 403 になる
  const isProd = getEnvironmentPath() === '';
  const index = isProd ? 2 : 3;

  //
  // 条件1：ログインページかどうか
  //
  const isAuthorized = paths[index] === 'signin';
  if (isAuthorized) return true;

  //
  // 条件2：403ページかどうか
  //
  const is403 = paths[index] === 'errors' && paths[index + 1] === '403';
  if (is403) return true;

  //
  // 条件3：認証エラーページかどうか
  //
  const isAuthError = paths[index] === 'errors' && paths[index + 1] === 'signin';
  if(isAuthError) return true;

  //
  // 条件4：非公開ページかどうか
  //
  const isUnavailable = paths[index] === 'errors' && paths[index + 1] === 'unavailable';
  if (isUnavailable) return true;

  //
  // 条件5：404ページかどうか
  //
  const is404 = paths[index] === 'errors' && paths[index + 1] === '404';
  if (is404) return true;


  return false;
}



/**
 * アクセス制御のヘルパー関数
 * @param statusCode HTTP ステータスコード
 * @param xErrorReason エラー理由
 */
export const redirectOnErrorStatus = (statusCode: Number, xErrorReason: string) => {

  // 200の場合は何もしない
  if (statusCode === 200) return

  //
  // 403はアクセス制御、404はヘルプセンター表示設定のエラー
  //
  if (statusCode === 403 || statusCode === 404) {

    // windowオブジェクトを取得
    const location = window.location

    // 環境名とプロジェクトキーを取得
    const environmentPath = getEnvironmentPath();
    const projectKey = getProjectKey();

    // 非公開設定のエラーページへリダイレクト
    if (statusCode === 404 && xErrorReason === 'not_public') {
      redirectIfAlready(`${environmentPath}/${projectKey}/errors/unavailable/`);
    }
    // 404のエラーページへリダイレクト
    else if (statusCode === 404) {
      redirectIfAlready(`${environmentPath}/${projectKey}/errors/404/`);
    }
    // 403でxErrorReasonがnot_authorizedの場合はログインページへリダイレクト
    else if (statusCode === 403 && xErrorReason === 'not_authorized') {
      redirectIfAlready(`${environmentPath}/${projectKey}/signin/`);
    }
    // 403の場合はエラーページへリダイレクト
    else {
      redirectIfAlready(`${environmentPath}/${projectKey}/errors/403/`);
    }
  }

  // 該当のエラーコードに当てはまらない場合は何もしない
  // return
}

/**
 * リダイレクト先にすでにいる場合は遷移しない
 * @param move リダイレクト先
 */
export const redirectIfAlready = (move: string) => {
  const location = window.location
  const pathname = location.pathname
  if (pathname === move) return;
  window.location.href = move;
}


/**
 * ログイン or ログアウト時のリダイレクト
 * @param status ログインかどうか(パスワード変更時はpasswordChangedとする)
 */
export const redirectOnAuthStatus = (status: 'signin' | 'signout' | 'passwordChanged') => {
  // 環境名とプロジェクトキーを取得
  const environmentPath = getEnvironmentPath();
  const projectKey = getProjectKey();

  // ログイン時のリダイレクト
  if (status === 'signin') {
    redirectIfAlready(`${environmentPath}/${projectKey}/`);
  }
  else if (status === 'signout') {
    // ログアウト時のリダイレクト
    redirectIfAlready(`${environmentPath}/${projectKey}/signin/`);
  }
  else if(status === 'passwordChanged') {
    // パスワード変更時のリダイレクト
    redirectIfAlready(`${environmentPath}/${projectKey}/signin/?status=passwordChanged`);
  }
}
