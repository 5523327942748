import DirectusClient from "@/services/DirectusClient";

/**
 * Custom fetchers used by React Query Codegen (https://www.graphql-code-generator.com/plugins/typescript-react-query)
 */

export const useGraphqlFetcher =
  <TData, TVariables>(
    query: string,
    options?: RequestInit['headers']
  ): ((variables?: TVariables) => Promise<TData>) =>
  async (variables?: TVariables): Promise<TData> => {
    console.log(JSON.stringify(variables, null, 2));

    try {
      const directus = DirectusClient.getInstance();

      // トークンのリフレッシュ
      await DirectusClient.refreshIfExpiredWrapper()

      const response = await directus.graphql.items(query, variables);

      return response.data as TData;
    } catch (error) {
      return error as any;
    }
  };

/**
 * ユーザー管理、ロール、権限など、システム全般に関連するデータ操作
 * @param query
 * @param options
 */
export const useGraphqlFetcherForSystem = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => async (variables?: TVariables): Promise<TData> => {
  console.log(JSON.stringify(variables, null, 2))

  try {
    const directus = DirectusClient.getInstance();

    // トークンのリフレッシュ
    await DirectusClient.refreshIfExpiredWrapper()

    const response = await directus.graphql.system(query, variables)

    return response.data as TData
  } catch (error) {
    console.error(error)
    // return error as any
    throw error as any
  }
}
