import React, {createContext, useEffect, ReactNode, useReducer, useMemo, useCallback, useContext} from 'react';
// type
import { ActionMap, AuthState, AuthUser, JWTContextType } from '@/types/auth';
// utils
import {cmsLogin, cmsLogout, getUserDataIfTokenIsValid} from "@/utils/custom/authHelper";
import {redirectOnAuthStatus} from "@/utils/custom/locationHelper";
//
import { GlobalSettingsContext } from '@/components/custom/contexts/GlobalSettingsProvider';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

const AuthProvider = ({ children }:AuthProviderProps) => {
  // グローバル設定の取得
  const { statue: globalState } = useContext(GlobalSettingsContext);
  // ユーザー情報の取得
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  // 画面表示時にトークンを持っているかの確認
  useEffect(() => {
    const initialize = async () => {
      try {

        const userDataIfValidToken = await getUserDataIfTokenIsValid()

        if (userDataIfValidToken) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: userDataIfValidToken,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

          // 環境名とプロジェクトキーを取得しリダイレクト
          // redirectOnAuthStatus('signout')
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);


  const login = useCallback(async (email: string, password: string) => {
    const user = await cmsLogin(email, password);
    dispatch({
      type: Types.Login,
      payload: { user },
    });
  }, [dispatch]);

  const logout = useCallback(async () => {
    await cmsLogout();
    dispatch({ type: Types.Logout });
  }, [dispatch]);

  //
  const method:'jwt' = 'jwt';
  const authValue = useMemo(() => ({
    ...state,
    method,
    login,
    logout,
  }), [state, login, logout, method]);


  // ログイン機能が無効な場合
  if (globalState?.is_enabled && globalState?.is_public) {
    return (
      <>
        {children}
      </>
    );
  }

  // ログイン機能が有効な場合
  return (
    <AuthContext.Provider
      value={authValue}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
